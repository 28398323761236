import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function DeletionPage() {
  return (
    <Layout>
      <SEO
        title="Account Deletions | Finding Beer"
        keywords={[]}
      />
      
      <section className="bg-white border-b py-8 text-black pt-24 p-8 ">
        <h1 className="my-4 text-3xl font-bold leading-tight">Account Deletion</h1>
        <p className="leading-normal mt-4">You may request an account deletion by completing this form.</p>
        <form className="grid form-submission mt-8 md:grid-cols-2" action="https://getform.io/f/9615a9ab-166d-4e94-8194-8f6dad2063d1" method="POST">
        <div class="relative mb-6">
        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-bold">Account Email address</label>
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none mt-7">
                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
            </div>
            <input type="text" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="jdoe@domain.com"/>
        </div>
        <div></div>
        <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>

        </form>
    </section>
    </Layout>
  );
}

export default DeletionPage;
